import { request } from './request'

/*查询城市列表*/
export function getCityList(parentId) {
  return request({
    url: '/Merchant/getRegion',
    method: 'post',
    params: {
      parent_id: parentId || 0
    }
  })
}

/*搜索*/
export function getCitySearch(cityname) {
  return request({
    url: '/city/cityesearch',
    params: {
      cityname
    }
  })
}
